



.blog .slick-slide > div {
    display: grid;
    place-items: center;
    width: 369px;
    margin: auto;
    padding: 0px;

  }

.list-card{
    display: flex;
    min-height: 80px;
    max-height: max-content;
    align-items: center;
background-color: #fff;
width: 100%;
padding: 30px 40px;
font-size: 24px;
font-weight: 400;
gap: 10px;
}

.back-btn{
    cursor: pointer;

}


.jd-desc{
    margin-top: 10px !important;
}

.back-btn {
    /* position:absolute;
    left:0; */

}

.blog .banner{
    position: relative;
    height: 450px !important ;

   
    
    }
 

    .blog .slick-list{
        height: 450px !important ;
      
    }
   
.back-btn svg{
    width:30px;
    height:30px;
}



.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

 .bannerBlog{
    height:'600px';
    width:'70vw';
    background-color:'#fff';
    border-radius:'12px';
    position:'relative';
    display:'flex';
    justify-content:'center'
 }
 .blog .banner{
    width:80vw;
}
.blog{
    display: flex;
    justify-content: center;
} 
.top-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
   
  }

.top-div-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}




@media screen and (max-width:770px){
    .blog{
        height: 300px;
    }
    .blog .banner,.blog .slick-list{
        height: 300px !important;
    }
    .blog-desc-img{
        width:90vw !important;
        height:450px !important;
      }
      .blog-desc-img>img{
        height:450px !important;
      }
      .top-div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
      }
      .top-div>svg{
        align-self: flex-start;
      }
      .top-div>span{
        width: 80vw;
      }
      .top-div-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    
}

@media screen and (max-width: 767px){
   
   
    .list-card{
        font-size: 16px;
        padding: 10px;
    }
   
    .bannerBlog{
        height: 300px !important;
    }
    .blog-title{
        font-size: 20px !important;
    }
   .blog-card .card-wrapper,.blog .blog-card{
        width:220px !important;
        height: 310px !important;
    }
    .blog .team-card{
      display: flex;
      justify-content: center;
       
    }
    .blog .slick-slide > div {
    width: 200px;
    }
    .blog-cards{
        grid-template-columns: repeat(auto-fill, minmax(197px, 1fr)) !important;
        gap: 0px 10px !important;
       }
  
}