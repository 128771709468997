.vacancyText {
    color: #005EB3;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
    height: 163px;
    background-color: #F3F9FF;
    border: 1px dashed #0086FF;
}

.ant-input:placeholder-shown {
    font-size: calc(1vw + 6px);
}

.ant-upload.ant-upload-drag {
    width: 40vw;
}
.jdTable{
    max-width: 600px;
    height: auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
 align-items: center;
    margin: 30px 30px;
  
 
    

}
.ant-upload-list-item-error .ant-upload-list-item-name,.ant-upload-list-item-error .ant-upload-text-icon>.anticon,.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon{
    color: #002546 !important;
}

.ant-upload-list-text{
    pointer-events:none !important;
}
.ant-upload-list-text .ant-upload-list-item-card-actions{
    pointer-events:fill !important;
  }
.list-card{
    display: flex;
    min-height: 80px;
    max-height: max-content;
    align-items: center;
background-color: #fff;
width: 100%;
padding: 30px 40px;
font-size: 24px;
font-weight: 400;
gap: 10px;
}
.jd-body{
    font-weight: 400;
    font-size: calc(1vw + 5px);
 
    width:300px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    background-color: #f5f5f5;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  
}
.jd-wrapper .back-btn{
    margin-top: 10px;
    margin-left: 0px;
   
    height: 30px;
}
/* .jd-heading{
    font-weight: 700;
    font-size: calc(1vw + 10px);    
    font-family: 'Sofia Sans', sans-serif;
    margin-top: 24px;
   text-align:center;
   width:100%;
   height:80px;
   display:flex;
   justify-content:center;
   align-items:center;
   background-color:#e4e3e3;
} */
.nojob{
    width:80%;
    height: 80%;
    object-fit: contain;
    
}
.jd-grid{
    display: flex;
   flex-direction: column;
    justify-content: center;
    align-items: center;

    gap:10px
    /* display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)); */
   
    
}
.jd-meta-data{
    display: flex;
    flex-direction: column;
}
.location-more{
    display: flex;
    align-items:center;
    gap:3px;
}
.location-less{
    display: none;
}
.jd-meta-heading{
    font-size: calc(1vw + 6px);
    font-weight: 700;
    max-width: 900px;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2.6em; /* Adjust to control the line height and spacing */
    line-height: 1.3; /* Adjust to control the line height */
    font-family: 'Sofia Sans', sans-serif;
  
    
}
.jd-list-card .location-meta-data{
    display: flex;
    flex-direction: row;
     gap:10px;
     align-items:center;
}
.line-icon{
    display: flex;
}

.jd-list-card{
    width: 80vw;
    height: 92px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
padding:15px 24px ;
border-radius: 5px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3 ease-in-out;
}
.jd-list-card:hover{
    transform: scale(1.01);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.11);
    transition: 0.3 ease-in-out;
}
.ant-form-item-control{
    max-width: 100%;
}

.jd-heading-top{
    font-size: 30px !important;
}
.heading-top{
    font-size: 30px !important; 
    display: flex !important;
    justify-content: center !important;
}
.jd-desc{
    margin-top: 10px !important;
    font-family: 'Inter', sans-serif;
}
.applyform .ant-form-item,.applyform .ant-upload.ant-upload-drag{
 
   display: flex;
    flex-direction: column;
  
    width: 50vw;

}
.applyform .ant-tooltip{
    display: none;
}

.applyform .ant-form-item-with-help .ant-form-item-explain{
    min-width: 90vw;
}
@media screen and (min-width: 1800px){
  
    .jd-grid{
        /* grid-template-columns: repeat(4, minmax(0, 1fr)); */
    }
  
}

@media screen and (max-width: 1360px){
  
    .jd-grid{
        /* grid-template-columns: 1fr 1fr; */
    }
  
}
 
@media screen and (max-width: 767px){
    .location-more{
        display: none;
    }
    .location-less{
    
        display: flex;
    align-items:center;
    gap:3px;
    }
    .jd-meta-heading{
        font-size: calc(1vw + 14px);
        font-weight: 700;
        font-family: 'Sofia Sans';
    }
    .jd-list-card{
        flex-direction: column;
        height: 150px;
        gap: 10px;
        justify-content: center;
       
    }
  
    .jd-meta-data{
        gap: 10px;
    }
.jd-apply-button{
   width: 150px;
   margin: 0 !important;
}
    .jd-body{
    font-size: calc(1vw + 12px);
    font-family: 'Inter', sans-serif;
    }
    /* .jd-heading{
        font-size: calc(1vw + 15px);
    } */
    .list-card{
        font-size: 16px;
        padding: 10px;
    }
    .jd-grid{
        /* grid-template-columns: 1fr; */
    }
    .applyform{
     width: 100%;
    }
    .jd-heading-top{
        font-size: 23px !important;
        display: flex !important;
        padding-top: 3px !important;
justify-content: flex-start !important;    }

.heading-top{
    font-size: 23px !important; 
}
.applyform .ant-form-item,.applyform .ant-upload.ant-upload-drag{
    width: 80vw;
    max-width: 100%;
}
.jd-wrapper .back-btn{
    margin-top: 0px;
    margin-left: 0px;
    padding-bottom: 10px;
    height: 30px;
}
  
}

/* jd description  */
.jd-description-head{
    width: 80vw;
    max-width: 700px;
    height: 66px;
    background-color: rgba(0, 134, 255, 1);
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    margin-top: 10px;

}
.jd-logo{
    width: 70px;
    height: 70px;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 15px;
    margin-top: 30px;
    margin-left: 22px;
    display: flex;
    justify-content: center;

}
.jd-apply-btn{
    width: 89px;
    height: 32px;
    border-radius: 3px;
    color: rgba(0, 134, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    font-size: calc(1vw + 2px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;


}
.jd-description-card{
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 700px;
    background-color: rgba(255, 255, 255, 1);
}
.jd-title{
    color: rgba(38, 50, 56, 1);
    font-size: calc(1vw + 15px);
    margin-top: 20px;
    line-height: 38px;
}
.jd-meta-row{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 14px;
    flex-wrap: wrap;
}
.jd-meta-tag{
    background-color: rgba(242, 242, 242, 1);
    width:auto;
    height: 24px;
    border-radius: 3px;
    padding: 12px 10px;
    color: rgba(87, 87, 87, 1);
    font-size: calc(1vw + 2px);
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 5px;
    

}
.jd-share{
    color: rgba(0, 134, 255, 1);
   
    width: auto;
    height: 24px;
    border-radius: 3px;
    padding: 10px 5px;
  cursor: pointer;
    font-size: calc(1vw + 2px);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.jd-pdf{
    /* border-top: 2px solid rgba(87, 87, 87, 1); */
    position: relative;
    width: 100%;
    padding: 5px;
    padding-right: 0px;
    height: 600px;
    display: flex;
    align-self: center;
    background-color:rgb(82, 86, 89);
    overflow-y: scroll;
}

.jd-pdf .react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
   margin-bottom: 5px;

}
.jd-pdf .react-pdf__Page__annotations{
    display: none !important;

}
.emp1-img{
    position:absolute;
    top: 3%;
    left: 6%;
    z-index: 0;
}
.emp2-img{
    position:absolute;
    top: 10%;
    right: 10%;
    z-index: 0;
}
.emp3-img{
    position:absolute;
    bottom: 4%;
    left: 13%;
    z-index: 0;
}
.emp4-img{
    position:absolute;
    bottom: 0%;
    right: 2%;
    z-index: 0;
}
.career-banner{
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    position:relative;
    height:600px;
    min-height:max-content;
    width:100%;
}
@media screen and (max-width: 760px){
    .jd-pdf{
        /* border-top: 2px solid rgba(87, 87, 87, 1); */
        position: relative;
        width: 100%;
        height: 600px;
        display: flex;
        align-self: center;
        background-color:rgba(87, 87, 87, 0.4) ;
    }
    .jd-description-card{
        height: auto;
    }
    .jd-pdf .react-pdf__Document {
        width: 100%;
        height: 100%;
    }
     .jd-pdf .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .jd-apply-btn{
        font-size: calc(1vw + 10px);
    }
    .jd-meta-tag,.jd-share{
        font-size: calc(1vw + 8px);
        min-width: auto;
        padding: 6px 5px;
    }
    .career-banner{
        display: flex;
        justify-content:flex-start;
        flex-direction: column;
        align-items: center;
        position:relative;
        height:600px;
        min-height:max-content;
        width:100%;
    }
    .emp1-img{
        position:absolute;
        top: 66%;
        left: 6%;
        z-index: 0;
    }
    .emp2-img{
        position:absolute;
        top: 60%;
        right: 10%;
        z-index: 0;
    }
    .emp3-img{
        position:absolute;
        bottom: -10%;
        left: -2%;
        z-index: 0;
    }
    .emp4-img{
        position:absolute;
        bottom: -6%;
        right: 2%;
        z-index: 0;
    }
}
@media screen and (max-width: 430px){
   .jd-list-card .location-meta-data{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        gap:3px;
    }
    .line-icon{
        display: none;
    }
}