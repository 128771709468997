.vacancyText {
    color: #005EB3;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
    height: 163px;
    background-color: #F3F9FF;
    border: 1px dashed #0086FF;
    
}
.background-image-1{
    position: relative;
  
    height: auto;
    min-height: max-content;
}

.ant-upload.ant-upload-drag {
    width: 40vw;
}
.ant-form-item-explain-error{
    width: 100%;
    text-align: left;
}
.ant-input-status-success{
    background: #fff !important;
}
.ant-input:placeholder-shown {
    font-size:16px !important;
}
textarea.ant-input {
    padding: 1vw 1vw;
    min-height: 80px;
    width: 100%;
}
.address-topline{
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: calc(1vw + 3px);
    text-align: center;
    width: 280px;
    max-width: max-content;
}
.address-card-heading{
   
        font-size: calc(2vw + 15px);
        font-weight: 700;
        display: block;
        width: max-content;
        position: relative;
        z-index: 2;
      
      
}
.map-div{
    height: 800px;
}
.ant-form-item {
    margin: 0 !important;
    margin-bottom: 10px !important;
}
.grid-wrapper{
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:100%;
    height: 100%;
}
.location-grid{

    display:grid;
    grid-template-columns: repeat(auto-fill,400px);
    grid-template-rows: repeat(auto-fill,160px);
    justify-content:center;
    gap:50px;
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:100%;
    height: 70%;
   
}
.contact-form{
    width: 100%;
}
.contact-wrapper{
    display:flex;
    width:100%;
    max-width: 497px;
    padding-left:20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom:10px;
    background:rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.11);
    text-align:center;
    height:auto;
    align-self:center;
    border-radius:4px;
}
.contact-wrapper-1{
    width:80vw;
 
    /* padding:60px; */
    /* background:rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11); */
    text-align:center;
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    gap:10px;
    position:relative;
}
.contact-icon{
   
    padding:2px;
}
@media screen and (max-width: 1050px){
    .address-card-heading {
        font-size: calc(2vw + 20px);
        width: auto;
      
      }
    
    }
@media screen and (max-width: 767px){
    .ant-form-item-explain-error{
        width: 100%;
        text-align: left;
    }
    .background-image-1{
        position: relative;
        height: auto;
        min-height: max-content;
    }
    .contact-icon{
        width: 20px;
        height: 20px;
    }
  
    .contact-form{
        width: 100% !important;
    }
    .contact-wrapper-1{
        width: 80vw;
        
        display: flex;
        flex-direction: column;
    }
    .contact-wrapper{
        display:flex;
        width:100%;
    margin-top: 20px;
        padding:20px;
    
        background:white !important;
        box-shadow:  0px 0px 31px 0px rgba(0, 0, 0, 0.11);
        text-align:center;
        height:auto;
        align-self:center;
        border-radius:4px;
    }
.address-topline {
    font-size: calc(1vw + 10px);
}
.address-card-heading {
    font-size: calc(2vw + 20px);
    width: auto;
  
  }
  .map-div{
    height: 880px;
}
  .location-grid{
    margin-bottom: 80px;
    display:grid;
    grid-template-columns: repeat(auto-fill,300px);
    grid-template-rows: repeat(auto-fill,160px);
    justify-content:space-around;
    gap:20px;
    height: 120%;
}
}

.responsive-img {
	width: 100%;
	height: auto;
}
.image-container {
    position: relative;

    display: flex;

}

#location-selected-1,#location-selected-2,#location-selected-3,#location-selected-4,#location-selected-0{
    opacity: 0;
}

.dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(0, 134, 255, 1);
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
}
.dot-selected{
    position: absolute;
    width: 19px;
    height: 19px;
    background-color: rgba(0, 134, 255, 0.3);
    border-radius: 50%;
    top: 50%;
     left: 50%;
     transform:translate(-50%,-50%);
    z-index: 1;
    
}

.location-card{
    position: absolute;
    width: 260px !important;
    height: 140px !important;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
    background-color: rgba(0, 134, 255, 0.3);
    border-radius: 4px;
    z-index: 2;
    cursor: pointer;
    border: 0.3px solid rgb(122, 121, 121,0.3);

}
@media screen and (max-width:980px) {
    .location-card{
        width: 160px!important;
       
        height: auto!important;
        padding: 5px !important;
        align-items: space-between;
        
    
    }
    .location-meta-data{
        display: none;
    }
    #location-card-1{
        transform: translate(-100%,-100%) !important;
       
    }
    #location-card-0{
        transform: translate(-100%,-100%) !important;
        
    }
}
@media screen and (max-width:770px) {
    .dot {
       
        width: 5px;
        height: 5px;
       
    }
    .dot-selected{
     
        width: 8px;
        height: 8px;
      
        
    }
}