.bannerContainer {
    /* background-image: url("./Banner.png");
    background-repeat: no-repeat;
    background-size: cover; */

    display: flex;
    justify-content: center;
   
}
.Banner-Img{
width: 100%;

height:100%;
}


.slick-dots{
    position: absolute;
   margin-bottom: 115px!important;
}
.slick-next:before{
   display: none;
    
}
.slick-prev:before{
    display: none;

}
.banner-wrapper{
    display: flex;
    flex-direction: column;
    height: 650px;
    position: relative;
    width: 100%;
    justify-content: center;
}
      
@media screen and (min-width: 2000px) {
    .banner-wrapper{
      
        max-height: max-content !important;
      
    }
    /* .Banner-Img{
        width: 700px;
      
        height: 700px !important;
        } */
}
@media screen and (max-width: 2000px ) {
    .banner-wrapper{
      
        max-height: max-content !important;
      
    }
}
@media screen and (max-width: 1400px ) {
    .banner-wrapper{
      
        max-height: max-content !important;
     
      
    }
  
    /* .Banner-Img{
        width: 500px;
      
        height: 500px !important;
        } */
}
@media screen and (max-width: 900px) {
    .bannerContainer{
      
        /* height: 70vh !important; */
        max-height: max-content !important;
       
        min-height: 100vh;
        display: flex;
        justify-content: center;
    }
    .banner-wrapper{
        height: max-content !important;
    }
    /* .Banner-Img{
        height: 320px;
        width: 320px;       
        } */
    .slick-dots{
        position: absolute;
        margin-bottom: 100px!important;
    }
    
       
}

@media  screen and (max-width:770px) {
    .slick-list{
        height:880px !important;
    }
    
    .Banner-Img{
        width: 80% !important;
        
        height:80% !important;
        }
}
@media screen and (max-width: 710px) {
  
    .bannerContainer{
        background-image: none;
        max-height: max-content !important;
        min-height: 100vh;
    }
    .slick-dots{
        position: absolute;
        margin-bottom: 0px!important;
    }
    /* .Banner-Img{
       display: none !important;
        } */
        .mobile{
            height: 360px;
            margin-top: 30px !important;
        }
        .banner-wrapper{
            justify-content: flex-start;
        }
        .banner-heading{
            line-height:40px;
        }
}
@media screen and (max-width:630px){
    .Banner-Img{
        width: 75% !important;
        
        height:75% !important;
        }
        .slick-list{
            height:770px !important;
        }
}

@media screen and (max-width:460px){
    .banner{
      
        height: max-content;
        
        
        }
        .banner-content{
            padding-left: 0px;
        }
        .Banner-Img{
            width: 95% !important;
            
            height:95% !important;
        
            }
            .slick-list{
                height:700px !important;
            }
}

@media screen and (max-height:700px){
    .Banner-Img{
        width: 300px !important;
        
        height:300px !important;
        }
       
}



