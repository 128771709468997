.terms-page__description{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    color: #111111;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
}
.terms-page__title{
    font-size: calc(2vw + 15px);
    font-weight: 700;
    text-align: center;
    font-family: 'Sofia Sans', sans-serif;
}
.terms-page{
    
    padding: 0px 8vw;
}
.terms-page__heading{
    font-size: calc(1vw + 10px);
    
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
}

@media screen and (max-width:770px){
    .terms-page__description{
        font-size: calc(1vw + 10px);
      
    }
    .terms-page__title{
        font-size: calc(2vw + 20px);
       
    }
    .terms-page__heading{
        font-size: calc(1vw + 15px);
      
      
    }
}
