
/* moving car styles */
* {
    padding: 0px;
    margin: 0px;
  }


  #lottie-car{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


  }


  #lottie-car div{
    width: 500px !important;
    height: 500px !important;
  }

  .sky {
    height: 70vh;
    width: 100%;
    background-position: center;
    overflow-x: hidden;
    z-index: 101;
  }

  .highway {
    width: 100%;
    height: 500px;
    margin-top: 40px;
    z-index: 4000;
    /* transform: translate(100px);  */
 
     /* background-repeat: repeat; */
    
  }
  .highway .car-image{
  
  
    position: absolute;
 
  
   width: 500px;
   left: 17%;
 
   position: absolute;
   bottom: 90px;
   z-index: 200;
   
  }
.animi{
  animation: highway 15s linear infinite; 
}
  @keyframes highway {
    100% {
      transform: translateX(-3000px);
    }
  }



  .car {
    width: 800px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    z-index: 200;
    /* margin-top: -170px; */
    margin-top: -50px;
  }

  .car img {
    width: 100%;
    animation: car 1s linear infinite;
  }

  @keyframes car {
    0% {
      transform: translateY(-4px);
    }

    50% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(-4px);
    }
  }

  .wheel {
    left: 51%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 150px;
    z-index: 200;
  }

  .wheel img {
    width: 80px;
    height: 80px;
    animation: wheel 1.5s linear infinite;
  }


  .frontwheel {
    position: absolute;
    left: 75px;
    margin-top: -4px;
    
  }

  .backwheel {
    position: absolute;
    left: -218px;
    margin-top: -4px;
   
  }
  .wheelanimi{
    animation: wheel 1s linear infinite;
  }
    @keyframes wheel {
      100% {
        transform: rotate(-360deg);
      }
    }
  .animi-overlay {
    width: 100vw;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #071b2f;
    opacity: 0;
    pointer-events: none;
    max-height: 100vh;
    overflow-y: auto;
    margin-top: 80px;
    z-index: 101;
    overflow: hidden;
   
  }
  .move {
    background-color: #071b2f;
    /* animation: slide 2s linear ; */
   animation:  highway 4s linear infinite; 
  }
  .animi-overlay.open {
    opacity: 0.8;
    pointer-events: inherit;
  }
  .animi-overlay .animi-modal {
    background-color:#091624;
   
    text-align: center;
    padding: 40px 80px;
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    width: 100%;
    z-index: 200;
   
  }
  .animi-overlay .animi-modal.open {
    opacity: 1;
    pointer-events: inherit;
  }

  @keyframes slide {
  
    
    100% {
      transform: translateX(-1000px);
    }
  }
 
  /* wave animation */
  
.circle-1 { position: absolute; top: 70%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 50px; height: 50px; background-color: #091624; color: white; text-align: center; line-height: 100px; border-radius: 50%; font-size: 1.3rem;z-index: -1; }
.circle-1:hover { cursor: pointer; }
.circle-1::after, .circle-1::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 80px; height: 80px; background: #091624; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-1::after { background: rgba(17,63,110,.6)}
.circle-1::after::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 80px; height: 80px; background: #091624; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-1::before { background: rgba(17,63,110,.6); -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
.circle-2 { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 80px; height: 80px; background-color: #9c4efb; color: white; text-align: center; line-height: 100px; border-radius: 50%; font-size: 1.3rem; }
.circle-2:hover { cursor: pointer; }
.circle-2::after, .circle-2::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 80px; height: 80px; background: #9c4efb; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-2::after { background: rgba(17,63,110,.6); }
.circle-2::after::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 80px; height: 80px; background: #091624; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-2::before { background: rgba(17,63,110,.6); -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
.circle-3 { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 80px; height: 80px; background-color: #fb8645; color: white; text-align: center; line-height: 100px; border-radius: 50%; font-size: 1.3rem; }
.circle-3:hover { cursor: pointer; }
.circle-3::after, .circle-3::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 80px; height: 80px; background: #fb8645; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-3::after { background: rgba(17,63,110,.6); }
.circle-3::after::before { content: ""; display: block; position: absolute; top: 0; left: 0; width: 80px; height: 80px; background: #091624; border-radius: 50%; z-index: -1; -webkit-animation: grow 3s ease-in-out infinite; animation: grow 3s ease-in-out infinite; }
.circle-3::before { background: rgba(17,63,110,.6); -webkit-animation-delay: -0.5s; animation-delay: -0.5s; }
@-webkit-keyframes grow {
	0% { -webkit-transform: scale(1, 1); transform: scale(1, 1); opacity: 1; }
	100% { -webkit-transform: scale(1.8, 1.8); transform: scale(1.8, 1.8); opacity: 0; }
}
@keyframes grow {
	0% { -webkit-transform: scale(1, 1); transform: scale(1, 1); opacity: 1; }
	100% { -webkit-transform: scale(1.8, 1.8); transform: scale(1.8, 1.8); opacity: 0; }
}


/* wifi wave animation */
.wifi-symbol {
  display: none;
}

.wifi-symbol [foo], .wifi-symbol {
  position: absolute;
  top: 210px;
  display: inline-block;
  width: 150px;
  height: 150px;
  left: 620px;
  transform: rotate(45deg) translate(100px);
}

.wifi-symbol .wifi-circle {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 21.42857px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-color: #00A9C2;
  border-style: solid;
  border-width: 1em 1em 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 0 100% 0 0;
  opacity: 0;
  -o-animation: wifianimation 2s infinite;
  -moz-animation: wifianimation 2s infinite;
  -webkit-animation: wifianimation 2s infinite;
  animation: wifianimation 2s infinite;
}

.wifi-symbol .wifi-circle.first {
  -o-animation-delay: 800ms;
  -moz-animation-delay: 800ms;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.wifi-symbol .wifi-circle.second {
  width: 5em;
  height: 5em;
  -o-animation-delay: 400ms;
  -moz-animation-delay: 400ms;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.wifi-symbol .wifi-circle.third {
  width: 3em;
  height: 3em;
}



/* wifi2 wave animation */
.wifi-symbol2 {
  display: none;
}

.wifi-symbol2 [foo], .wifi-symbol2 {
  position: absolute;
  top: 350px;
  display: inline-block;
  width: 150px;
  height: 150px;
  left: 20px;
  transform: rotate(-135deg) translate(100px);
}

.wifi-symbol2 .wifi-circle {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 21.42857px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-color: #00A9C2;
  border-style: solid;
  border-width: 1em 1em 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 0 100% 0 0;
  opacity: 0;
  -o-animation: wifianimation 2s infinite;
  -moz-animation: wifianimation 2s infinite;
  -webkit-animation: wifianimation 2s infinite;
  animation: wifianimation 2s infinite;
}

.wifi-symbol2 .wifi-circle.first {
  -o-animation-delay: 800ms;
  -moz-animation-delay: 800ms;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.wifi-symbol2 .wifi-circle.second {
  width: 5em;
  height: 5em;
  -o-animation-delay: 400ms;
  -moz-animation-delay: 400ms;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.wifi-symbol2 .wifi-circle.third {
  width: 3em;
  height: 3em;
}

@keyframes wifianimation
{
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}

@-moz-keyframes wifianimation
{
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}

@-webkit-keyframes wifianimation
{
  0% {
    opacity: 0.4;
  }
  5% {
    opactiy: 1;
  }
  6% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 0.1;
  }
}

/* mobile screen  */
@media (max-width:1000px) {
  .car{
    width: 400px;
    margin-top: 0px;
  }
  .frontwheel{
    left: 52px;
    margin-top: -8px;
  }
  .backwheel{
    left: -112px;
    margin-top: -4px;
  }
  .wheel img{
 width: 43px;
 height: 43px;
 bottom: 133px;
  }
  .highway {
    
   /* transform: translate(400px);  */
   margin-top: 170px;
   position: absolute;
     background-repeat: repeat;
    
  }
  .animimobile{
    animation: highway 11s linear infinite;
  }
  .circle-1 {
position:relative;
left: 50%;
top: 50%;
transform: translate(-46%, 600%);

  }
  #lottie-car div{
    width: 200px !important;
    height: 200px !important;
  }
  .animi-overlay .animi-modal{
    padding: 10px;
  }
  .animation-file{
    width: 250px;
    height: 250px;
  }
  .highway .car-image{
  width: 280px;
   left: 0%;
 position: relative;

   bottom: -140px;
   z-index: 200;
   
  }
  @keyframes highway {
    100% {
      transform: translateX(-2500px);
    }
  }
  .wifi-symbol [foo], .wifi-symbol{
    left: 260px;
  }
  .wifi-symbol2 [foo], .wifi-symbol2{
    left: -20px;
  }

}
@media screen and (max-width:425px) {
  .animi-overlay {
    top: -2.5%;
  }
}