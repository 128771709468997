.card-modal .card-model-heading{
    font-size: calc(1vw + 10px);
    font-family: 'Sofia Sans', sans-serif;
    font-weight:700;
    color:#0086FF;
max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-modal .card-model-content{
    font-size: calc(1vw + 3px);
    font-family:'Inter', sans-serif;
    font-weight:500;
    color:rgba(0, 37, 70, 1);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



@media screen and (max-width:767px){
    .card-modal .card-model-content{
        font-size: calc(1vw + 10px);
    }
    .card-modal .card-model-heading{
        font-size: calc(1vw + 15px);
    }
   
}