
#lid {
    animation: blink 2.4s infinite;
  }
  #eye {
    animation: squeeze 2.4s infinite;
  }
  
  @keyframes blink {
    90% {
      transform: none;
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(150px) scaleY(0)
    }
    100% {
      animation-timing-function: ease-out;
    }
  }
  @keyframes squeeze {
    90% {
      transform: none;
      animation-timing-function: ease-in;
    }
    93% {
      transform: translateY(3px) scaleY(0.8)
    }
    100% {
      animation-timing-function: ease-out;
    }
  }