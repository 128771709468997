
  
  .Emagazine-carousel {
    position: relative;
    
    max-width: 520px;
    min-width: 500px;
   min-height: 600px;
    perspective: 500px;
    transform-style: preserve-3d;
    margin: 0 auto;
 
  }
  
  .Emagazine-card-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.4)) translateZ(calc(var(--abs-offset) * -30rem)) translateX(calc(var(--direction) * -5rem));
    filter: blur(calc(var(--abs-offset) * 0.5rem));
    transition: all 0.3s ease-out;
  }
  
  .Emagazine-card {
    width: 100%;
    height: 100%;
    padding: 2rem;
 
    border-radius: 1rem;
    color: #9CA3AF;
    text-align: justify;
    transition: all 0.3s ease-out;
  }

  .Emagazine-nav {
    color: white;
    font-size: 5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;
  }
  .Emagazine-nav.left {
    left: -50px;
    /* transform: translateX(100%) translatey(-50%); */
  }
  .Emagazine-nav.right {
    right: -50px;
    /* transform: translateX(-100%) translatey(-50%); */
  }

  .Emagazine-cover{
    width:100%;
    height:100%;
    position:absolute;
     top:50%;
     left:50%;
     transform:translate(-50%,-50%);
     cursor:pointer;
  }
  @media  screen and (max-width:2000px) {
    .Emagazine-carousel {
      position: relative;
      max-width: 360px;
      min-width: 300px;
     min-height: 460px;
       margin: 0 auto;
     
      perspective: 400px;
      transform-style: preserve-3d;
   
    }
  }
  @media  screen and (max-width:767px) {
    .Emagazine-carousel {
      position: relative;
      max-width: 320px;
      min-width: 300px;
     min-height: 400px;
       margin: 0 auto;
     
      perspective: 400px;
      transform-style: preserve-3d;
   
    }
    .Emagazine-cover{
      /* width:280px;
      height:360px;
      position:absolute;
       top:50%;
       left:50%;
       transform:translate(-50%,-50%);
       cursor:pointer; */
    }
    .Emagazine-nav.left {
  /* left: 0; */
      /* transform: translateX(-250%) translatey(-50%); */

    }
    .Emagazine-nav.left svg,.Emagazine-nav.right svg{
      width: 30px;
      height: 30px;
    }
    .Emagazine-nav.right {
      /* right: 0; */
      /* transform: translateX(250%) translatey(-50%); */
    }
  
  }
  @media  screen and (max-width:660px) {
    .Emagazine-carousel {
      position: relative;
      max-width: 260px;
      min-width: 260px;
     min-height: 380px;
       margin: 0 auto;
     
      perspective: 400px;
      transform-style: preserve-3d;
   
    }
  }

  @media  screen and (max-width:540px) {
    .Emagazine-carousel {
      position: relative;
      max-width: 200px;
      min-width: 200px;
     min-height: 300px;
       margin: 0 auto;
    
      perspective: 300px;
      transform-style: preserve-3d;
   
    }
    .not-active{
 
      overflow: hidden;
    }
    /* .Emagazine-cover{
      width:250px;
      height:300px;
      position:absolute;
       top:50%;
       left:50%;
       transform:translate(-50%,-50%);
       cursor:pointer;
    }
    .Emagazine-nav.left {
      transform: translateX(-250%) translatey(-50%);
    }
    .Emagazine-nav.right {
      right: 0;
      transform: translateX(250%) translatey(-50%);
    } */
    .Emagazine-nav.left {
      left: -40px;
      /* transform: translateX(100%) translatey(-50%); */
    }
    .Emagazine-nav.right {
      right: -40px;
      /* transform: translateX(-100%) translatey(-50%); */
    }
  }
  @media  screen and (max-width:370px) {
    .Emagazine-carousel {
      position: relative;
      max-width: 160px;
      min-width: 160px;
     min-height:200px;
       margin: 0 auto;
    
      perspective: 220px;
      transform-style: preserve-3d;
   
    }
  }