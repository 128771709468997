/* body {
    font-family: Open Sans;
  }
  h1 {
    margin-bottom: 0px;
  }
  .container {
    display: block;
    width: 90%;
    height: 700px;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
  }
  .row {
    position: absolute;
    width: 100%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .col1 {
    width: 33%;
    height: auto;
  }
  .col2 {
    width: 33%;
    height: auto;
    margin-left: calc(33% + 10px);
  }
  .col3 {
    width: 33%;
    height: auto;
    margin-left: calc(66% + 20px);
  }
  .gallery {
    position: relative;
    z-index: 4;
  }
  .gallery .gallery_item {
    padding-bottom: 10px;
    text-align: left;
  }
  .gallery .gallery_item img {
    width: 100%;
    height: auto;
  }
   */

   /*------------------------------------*\
    MATERIAL PHOTO GALLERY
\*------------------------------------*/

/* :root {
    --dark: #FFF;
    --shadow: rgba(0,0,0,.2);
  }
  

  
  .container > a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
  }
  
  .container > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 2px 16px var(--shadow);
  }
  
  .container {
    display: grid;
  
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    grid-auto-rows: 120px;
    grid-auto-flow: dense;
  }
  
  .horizontal {
    grid-column: span 2;
  }
  
  .vertical {
    grid-row: span 2;
  }
  
  .big {
    grid-column: span 2;
    grid-row: span 2;
  } */

  /* ////new */
  .grid-wrapper-gallery{
    max-height: 100vh;
    overflow-y: scroll;
    
  }
 
  .gallery-grid img {
    max-width: 100%;
    display: block;
    height: auto;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
    align-items: stretch;
    justify-items: center;
    margin: 0;
    padding: 0;
  }
  
  .wide {
    grid-column: span 1;
  }
  
  .tall {
    grid-row: span 2;
  }

  @media screen and (max-width:770px) {
    .grid-wrapper-gallery{
        max-height: 80vh;
        overflow-y: scroll;
        
      }
    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        grid-auto-rows: 100px;
        grid-auto-flow: dense;
        align-items: stretch;
        justify-items: center;
        margin: 0;
        padding: 0;
      }
      
      .wide {
        grid-column: span 1;
      }
      
      .tall {
        grid-row: span 2;
      }
  }